'use client';

import { ComponentProps, forwardRef } from 'react';

import analyticsManager from '@/lib/analytics';
// eslint-disable-next-line no-restricted-imports
import { Link as NextIntlLink } from '@/lib/translation/hooks';
import { Locale } from '@/lib/translation/types';

import extractText from './extract-text';

type ExtraLinkProperty = { linkCategory?: 'menu' | 'button' };

type LinkProps = ComponentProps<typeof NextIntlLink> & ExtraLinkProperty;

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, linkCategory = 'button', ...props }, ref) => {
    const defaultValue = extractText(children);
    // Avoid click to go to children of the link
    return (
      <NextIntlLink
        {...props}
        ref={ref}
        locale={props.locale as Locale}
        onClick={(e) => {
          analyticsManager.client.sendEvent({
            event: 'click_cta',
            link_category: linkCategory,
            link_text: props['aria-label'] ?? defaultValue ?? 'unknown',
            link_url: props.href.toString(),
          });
          if (props.onClick) {
            props.onClick(e);
          }
        }}
      >
        {children}
      </NextIntlLink>
    );
  },
);

export default Link;
