'use client';

// In this precise case we need to retrieve the params aswell
// We cannot guess which one it'll be so we avoid complexity by using the
// next-navigation one
// eslint-disable-next-line no-restricted-imports
import { usePathname } from 'next/navigation';
import { useTranslations } from 'next-intl';

import { Button } from '@/app/_components/ui/button';
import Routes from '@/lib/router/routes';
import Link from '@/lib/translation/components/link';

const AnonymousCtas = () => {
  const pathname = usePathname();

  const searchParams =
    Routes.Home !== pathname
      ? `?${new URLSearchParams({ origin: pathname }).toString()}`
      : '';

  const t = useTranslations();

  return (
    <div className="hidden gap-4 lg:flex">
      <Link
        rel="nofollow"
        linkCategory="menu"
        href={`${Routes.SignIn}${searchParams}`}
      >
        <Button size="lg" variant="outline">
          {t('auth.signIn.title')}
        </Button>
      </Link>
      <Link
        rel="nofollow"
        linkCategory="menu"
        href={`${Routes.SignUpRegister}${searchParams}`}
      >
        <Button size="lg" data-testid="sign-up-button">
          {t('auth.signUp.cta')}
        </Button>
      </Link>
    </div>
  );
};

export default AnonymousCtas;
