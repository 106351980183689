import { Formats, TranslationValues } from 'next-intl';
import { Pathnames } from 'next-intl/routing';

import clientConfig from '@/lib/config/client-config';

import { POSSIBLE_LOCALES } from './types';

export const i18n = {
  // The env zod schema ensures there is at least one locale,
  // therefore, we can safely use it as the `defaultLocale`.
  defaultLocale: clientConfig.activeLocales[0],
  locales: clientConfig.activeLocales,
  localePrefix: 'as-needed' as const,
};

export type TFunction = (
  key: string,
  values?: TranslationValues,
  formats?: Partial<Formats>,
) => string;

export const pathnames = {
  '/projects': {
    'fr-FR': '/investir',
    'en-US': '/projects',
  },
  '/projects/[slug]': {
    'fr-FR': '/investir/[slug]',
    'en-US': '/projects/[slug]',
  },
  '/get-funded': {
    'fr-FR': '/lever-des-fonds',
    'en-US': '/get-funded',
  },
  '/how-it-works': {
    'fr-FR': '/comment-ca-marche',
    'en-US': '/how-it-works',
  },
  '/sign-in': {
    'fr-FR': '/connexion',
    'en-US': '/sign-in',
  },
  '/about-us/who-we-are': {
    'fr-FR': '/a-propos/qui-sommes-nous',
    'en-US': '/about-us/who-we-are',
  },
  '/about-us/press': {
    'fr-FR': '/a-propos/presse',
    'en-US': '/about-us/press',
  },
  '/partners/cgp': {
    'fr-FR': '/partenaires/conseillers-gestion-patrimoine',
    'en-US': '/partners/cgp',
  },
} satisfies Pathnames<typeof POSSIBLE_LOCALES>;
